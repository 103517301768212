import {initializeApp}  from 'firebase/app'
const firebaseConfig = {
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_authDomain,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_projectId,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_storageBucket,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_messagingSenderId,
        appId: process.env.NEXT_PUBLIC_FIREBASE_appId,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_measurementId
}

const firebaseCloudMessaging = {
    //initializing firebase app
    init: async function () {
        const firebaseApp = initializeApp(firebaseConfig);
        try {
          const messaging = firebaseApp.messaging()
          //requesting notification permission from browser
          const status = await Notification.requestPermission()
          if (status && status === 'granted') {
            //getting token from FCM
            const fcm_token = await messaging.getToken({
              vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAP_ID
            })
            if (fcm_token) {
              //return the FCM token after saving it
              return fcm_token
            }
          }
        } catch (error) {
          console.error(error,"error")
          return null
        }
    },
    delToken : async () =>{
        const firebaseApp = initializeApp(firebaseConfig);
        try {
            await firebaseApp.messaging.delToken();
            return true
        } catch (error) {
            console.error(error,"error")
            return null
        }
    }
  }
  export { firebaseCloudMessaging }