import dynamic from "next/dynamic";
import Head from 'next/head';
import {useRouter} from 'next/router';
import {Fragment, useContext, useEffect, useState} from 'react';
// import CryptoJS from "crypto-js";
import axios from 'axios';
import 'firebase/messaging'
import {firebaseCloudMessaging} from '../helper/firebaseHelper'

// API helper
import {apiHelperBlockchain} from '../helper/apiHelper';

// styles
import layoutStyles from '../styles/layout.module.scss';

import {chainId, chainIdHEX as LocalChainID, POLYGON_CHAIN_IDS_Int} from "../config/ethereum-config";

import {useAccount, useConnect, useDisconnect, useNetwork, useSignMessage, useSwitchNetwork} from 'wagmi';

//moralis
import {useMoralis} from "react-moralis";

import AppBanner from "smart-app-banner-react";
import {useTranslation} from 'next-i18next';
import {fetchBalance, watchAccount, watchNetwork} from '@wagmi/core'
import {ThemeContext} from "../context/Context";
import ConstHelper from "../helper/constHelper";
import {moralisAuthUrl} from '../env_development';

const Header = dynamic(() => import('./header'));
const Footer = dynamic(() => import('./footer'));
const NetworkModal = dynamic(() => import('../components/common/networkModal'))
const SignWithDiffAccount = dynamic(() => import('./common/signWithDiffAccount'))

export default function Layout({ children, pathname }) {

  const [networkChangeModal, setNetworkChangeModal] = useState(false);
  const [triggerSignature, setTriggerSignature] = useState(false);
  const [isNews, setIsNews] = useState(true);
  let router = useRouter()
  // const { boot, update } = useIntercom();
  const { signMessageAsync } = useSignMessage();
  const { connectAsync } = useConnect();

  const { disconnectAsync } = useDisconnect()

  // Change network trigger
  watchNetwork(async (network) => {
    if (chainId[0] === network?.chain?.id) {
      localStorage.setItem("chainIndex", "1");
      localStorage.setItem("chain_id", chainId[0]);
    } else {
      if (chainId[1] === network?.chain?.id) {
        localStorage.setItem("chain_id", chainId[1]);
      }
      localStorage.setItem("chainIndex", "2");
    }
  })

  // Change account trigger
  watchAccount(async (account) => {
    const check_local_walletAddress = localStorage.getItem("walletAddress")
    const userdata = localStorage.getItem("userData")
    if (account && account?.address && (account?.address?.toLowerCase() !== check_local_walletAddress?.toLowerCase())) {
      if (userdata) {
        if (!triggerSignature) {
          setTriggerSignature(true);
        }
      }
    }
  })
  const getRequestMessageForSignIn = async(address, chainId, domain) =>{
    try {
      const payload = {
        address, chainId, domain, uri : process.env.domainUrl, timeout : 15
      }

      const apiKEy = process.env.NEXT_PUBLIC_x_api_key

      const {data} = await axios({
        url : moralisAuthUrl,
        method : "POST",
        data : payload,
        headers :{
          "X-API-Key" : apiKEy
        }
      })

      return data


    } catch (error) {
      return error
    }

  }

  const signWithDiffACcounnt = async () => {
    if (address) {
      const chainTypeLocal = localStorage.getItem("chainIndex");
      localStorage.removeItem("userData")
      localStorage.removeItem("walletAddress")
      localStorage.removeItem("accessToken")
      localStorage.removeItem("user_id")
      await firebaseCloudMessaging.delToken();
      await disconnectAsync()

      await connectAsync({ connector: connectors[0] });
      // router.reload(window.location.pathname)
      const domain = process.env.domain;
      const messageReq = await getRequestMessageForSignIn(address.toLowerCase(), chainTypeLocal, domain)
      const message = messageReq?.message
      await signMessageAsync({ message }).then(async (res) => {
        if (res) {
          localStorage.setItem("connectorID", "injected")
          localStorage.setItem("walletAddress", address.toLowerCase());

          let wData = { wallet_address: address.toLowerCase(), message, signature: res };
          // initialise-user with UPYO
          await apiHelperBlockchain('initialise-user', 'POST', wData).then(res => {
            if (res.data.status === true) {
              localStorage.setItem("accessToken", res?.data?.data?.accessToken);
              localStorage.setItem("userData", JSON.stringify(res?.data?.data?.[0]));
              if (parseInt(chain?.id) === LocalChainID[1]) {
                localStorage.setItem("chainIndex", 2);
                localStorage.setItem("chainType", "polygon");
              } else if (parseInt(chain?.id) === LocalChainID[0]) {
                localStorage.setItem("chainIndex", 1);
                localStorage.setItem("chainType", "ethereum")
              }
              // window.location = "/" + props.currLocale + "/nft";
              const wallet_address_check = localStorage.getItem("walletAddress")
              localStorage.setItem("userData", JSON.stringify(res?.data?.data?.[0]));
              router.reload(window.location.pathname)

            }
          }).catch(error => {
            router.reload(window.location.pathname)

          });
        }
      });

    }
  }

  const disconnectForDiffAcc = async () => {
    const userdata = localStorage.getItem("userData")
    if (userdata) {
      localStorage.removeItem("userData")
      localStorage.removeItem("walletAddress")
      localStorage.removeItem("accessToken")
      localStorage.removeItem("user_id")
      await firebaseCloudMessaging.delToken();
      await disconnectAsync()
      router.reload()
    }
  }
  const { Moralis } = useMoralis();
  const { switchNetwork } =
    useSwitchNetwork()
  const { chain } = useNetwork()
  const { connector: activeConnector, isConnected, address } = useAccount();
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const callAsyncFunc = async () => {
      const options = {
        method: 'GET',
        url: `https://deep-index.moralis.io/api/v2/${address}/balance`,
        params: { chain: chain?.id === parseInt("0x1") ? "mainnet" : chain?.id === parseInt("0x13881") ? "mumbai" : chain?.id === parseInt("0x5") ? "goerli" : "matic" },
        headers: { accept: 'application/json', 'X-API-Key': 'ytHvdq0bKzDMNuYnWyyArowhz4tZpqTCEvOZLnOYcLr571h8BSRDvnduSiV94LCO' }
      };

      axios
        .request(options)
        .then(function (response) {
          const bal = response?.data?.balance || 0;
          setBalance(bal)
        })
        .catch(function (error) {});
    }
    if(address){
      callAsyncFunc()
    }
  }, [fetchBalance, chain, isConnected, balance])

  const { connect, connectors } =
    useConnect()

  const bal = balance || 0;
  const tokenValue = Moralis.Units.FromWei(bal);
  const { t } = useTranslation('common')

  const appInfoEns = {
    imageUrl: `${process.env.hostBaseUrl}/ENSAppIcon.png`,
    name: t('UPYO_ENS'),
    publisher: t('UPYO_inc'),
    infoText: t('play_store'),
    linkButtonText: t('view'),
    linkUrl: "https://play.google.com/store/apps/details?id=com.upyo.name&hl=en_IN&gl=US"
  };

  useEffect(() => {
    const wallet_address_from_local = localStorage.getItem("walletAddress")
    const userdata = localStorage.getItem("userData")
    const currentChainId = localStorage.getItem("currentChainIndex")
    const walletConnect = JSON.parse(localStorage.getItem("walletconnect"))
    if ((!isConnected && wallet_address_from_local && currentChainId) || (walletConnect && walletConnect.connected && wallet_address_from_local)) {
      connect({ connector: connectors[0] });
      localStorage.removeItem("currentChainIndex")
    }
    else {
      if (!isConnected && userdata && !currentChainId) {
        disconnectForDiffAcc()
      }
    }

  }, [chain, isConnected, chainId])

  useEffect(() => {

    if (isConnected && address && chain) {
      if (chainId.includes(chain?.id)) {
        localStorage.setItem('chain_id', chain?.id);
        if (address) {
          localStorage.setItem("walletAddress", address)
        }
        // set chainType
        if (POLYGON_CHAIN_IDS_Int.includes(chain?.id)) { localStorage.setItem('chainType', "polygon"); }
        else { localStorage.setItem('chainType', "ethereum"); }

        setNetworkChangeModal(false)
        localStorage.removeItem("currentChainIndex")
      }
      else { setNetworkChangeModal(true) }
    } else {
      setNetworkChangeModal(false)
      localStorage.removeItem("currentChainIndex")
    }
  }, [isConnected, address, chain, chainId])

  useEffect(() => {
    const callAsyncFunc = async () => {
      const wallet_address_check = localStorage.getItem("walletAddress")

      const initialiseUser = async (walletAddress) => {
        if (walletAddress) {

          let wData = { wallet_address: walletAddress };

          // initialise-user with UPYO
          await apiHelperBlockchain('initialise-user', 'POST', wData).then(res => {
            if (res.data.status === true) {
              localStorage.setItem("accessToken", res?.data?.data?.accessToken);
              if (parseInt(chain?.id) === chainId[1]) {
                localStorage.setItem("chainIndex", 2)
              } else if (parseInt(chain?.id) === chainId[0]) {
                localStorage.setItem("chainIndex", 1)
              }

              localStorage.setItem("userData", JSON.stringify(res.data.data));

            }
          }).catch(() => {});
        }
      }

      if (balance && wallet_address_check) {
        const bal = balance || 0;
        const tokenValue = Moralis.Units.FromWei(bal);
        localStorage.setItem("accountBalance", tokenValue);
      }

      if (address && wallet_address_check && (wallet_address_check != address)) {
        localStorage.setItem("walletAddress", address);
        await initialiseUser(address);
        if (balance) {
          const bal = balance || 0;
          const tokenValue = Moralis.Units.FromWei(bal);
          await localStorage.setItem("accountBalance", tokenValue);
        }
        localStorage.setItem("storedData", "");
        router.reload(window.location.pathname)
      }
    }
    callAsyncFunc();
    // localStorage.removeItem("walletAddress");
    // localStorage.removeItem("accountBalance");
  }, [isConnected, address, chain, balance]);

  useEffect(() => {
    const callAsyncFunc = async () => {
      if (chain?.id) {
        const balanceFromLocal = localStorage.getItem("accountBalance");
        if (balanceFromLocal != tokenValue) {
          await localStorage.setItem("accountBalance", tokenValue);
        }
      }
    }
    callAsyncFunc();
  }, [chain, balance])

  // Switch Chains
  useEffect(() => {
    const fromLocalStorage = localStorage.getItem("chain_id")
    if (!chainId.includes(chain?.id)) {
      // const fromLocalStorage = localStorage.getItem("chain_id")
      if (fromLocalStorage) {
        switchNetwork?.(parseInt(fromLocalStorage));
      } else {
        switchNetwork?.(parseInt(chainId[0]));
      }
    }
    const chain_id = localStorage.getItem("chain_id")
    if (parseInt(chain?.id) === chainId[1]) {
      localStorage.setItem("chainIndex", 2)
    } else if (parseInt(chain?.id) === chainId[0]) {
      localStorage.setItem("chainIndex", 1)
    }
  }, [isConnected, chain, address])


  const {currentTheme, setCurrentTheme} = useContext(ThemeContext);

  const toggleTheme = () => {

    const themeName = (currentTheme === ConstHelper.LIGHT_THEME ? ConstHelper.DARK_THEME : ConstHelper.LIGHT_THEME)
    setCurrentTheme(themeName);
    if (typeof document !== 'undefined') {
      document.body.setAttribute("data-theme", themeName);
    }
    localStorage.setItem('theme', themeName);
  }

  /*useEffect(() => {
    if (localStorage.getItem('walletAddress') && localStorage.getItem('walletAddress') !== '') {
      let secret = process.env.NEXT_PUBLIC_INTERCOM_ID_SECRET_ID;
      let cryptoJS = CryptoJS;
      let stringID = (localStorage.getItem('walletAddress')).toString();
      let hash = cryptoJS.HmacSHA256(stringID, secret);
      let hex = cryptoJS.enc.Hex.stringify(hash);
      boot({ user_hash: hex })
    }
  })*/

  let meta = children.props && children.props.meta
  let canonical = children.props.canonical ? children.props.canonical : process.env.hostBaseUrl + '/' + router.locale + router.asPath;
  let ogImage = children.props.feature_image && children.props.feature_image !== '' ? children.props.feature_image : process.env.hostBaseUrl + '/img/og_image/' + (children.props.ogImage ? children.props.ogImage : 'upyo-default.png');
  let imageExt = ogImage && ogImage !== '' ? ogImage.split('.').pop() : 'image/webp'
  let ogUrl = canonical;
  let dynamicUrl = (children.props.dynamicURL ? children.props.dynamicURL : router.asPath);
  let isSafari = false;
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    isSafari = (/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }

  useEffect(() => {
    let url = pathname.split('/');
    setIsNews(url.includes('news') || url.includes('article'));
  }, [pathname])


  useEffect(() => {
    if(localStorage.getItem("userData")){
      setToken()
    }

    async function setToken() {
      try {
        const token = await firebaseCloudMessaging.init()
        if (token) {
          const accessToken = localStorage.getItem("accessToken");
          let authentication = null;
          if (accessToken) {
              authentication = { "authorization": `Bearer ${accessToken}` }
          }
          await apiHelperBlockchain("notifications/add-fcm", "POST", {fcm_token : token}, authentication )
          
        }
      } catch (error) {
      }
    }
  })

  let equivalentSlug = ''
  if (children.props.article && children.props.article.equivalentSlug && children.props.article.equivalentSlug !== '') {
    equivalentSlug = '/article/' + children.props.article.equivalentSlug
  } else if (children.props.equivalentSlug && children.props.equivalentSlug !== '') {
    equivalentSlug = children.props.equivalentSlug
  }

  const isNewsPage = children.props.isNewsPage ? children.props.isNewsPage : (router.asPath && (router.asPath.includes('/news') || router.asPath.includes('/article') || router.asPath.includes('/editor')))
  const page = children.props && children.props.pageNumber
  const totalNumberOfPages = children.props.totalNumberOfPages && children.props.totalNumberOfPages
  return (
    <>
      <Head>
        <title>{meta && meta.pageTitle ? meta.pageTitle : 'UPYO-NFT'}</title>
        <meta name="application-name" content="UPYO" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="UPYO" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-tap-highlight" content="no" />

        {isNewsPage &&
          <>
            <link rel="alternate" type="application/rss+xml" title="UPYO News" href={`${process.env.hostBaseUrl}/${children.props.currLocale ? children.props.currLocale : 'ar'}/news/feed.xml`} />
            {(isSafari && (router && router.pathname === '/news')) && <meta name="apple-itunes-app" content="app-id=1639488983" />}
          </>
        }
        {!dynamicUrl.includes('/article/') && !dynamicUrl.includes('/events') && (router.pathname && router.pathname !== "/news/[categorySlug]") && (router.pathname && router.pathname !== "/events/[event]") && (router.pathname && router.pathname !== "/events/category/[categorySlug]") && (router.pathname && router.pathname !== "/news/tags/[tagSlug]") ?
          <>
            <link rel="alternate" hrefLang='en' href={process.env.hostBaseUrl + '/en' + dynamicUrl} />
            <link rel="alternate" hrefLang='x-default' href={process.env.hostBaseUrl + '/en' + dynamicUrl} />
            <link rel="alternate" hrefLang='ar' href={process.env.hostBaseUrl + '/ar' + dynamicUrl} />
          </> :
          equivalentSlug !== '' ?
            <>
              <link rel="alternate" hrefLang='en' href={process.env.hostBaseUrl + '/en' + (children.props.currLocale === 'en' ? dynamicUrl : equivalentSlug)} />
              <link rel="alternate" hrefLang='x-default' href={process.env.hostBaseUrl + '/en' + (children.props.currLocale === 'en' ? dynamicUrl : equivalentSlug)} />
              <link rel="alternate" hrefLang='ar' href={process.env.hostBaseUrl + '/ar' + (children.props.currLocale === 'ar' ? dynamicUrl : equivalentSlug)} />
            </> :
            <>
              {/* <link rel="alternate" hrefLang={children.props.currLocale} href={process.env.hostBaseUrl + '/' + children.props.currLocale + dynamicUrl} />
              {children.props.currLocale === 'en' ? <link rel="alternate" hrefLang='x-default' href={process.env.hostBaseUrl + '/en' + dynamicUrl} /> : null} */}
            </>
        }

        <link rel="shortcut icon" href={`${process.env.hostBaseUrl}/favicon.png`} />
        <link rel="icon" href={`${process.env.hostBaseUrl}/favicon.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.hostBaseUrl}/favicon-16x16.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.hostBaseUrl}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="48x48" href={`${process.env.hostBaseUrl}/favicon-48x48.png`} />
        <link rel="icon" type="image/png" sizes="96x96" href={`${process.env.hostBaseUrl}/favicon-96x96.png`} />

        <link rel="icon" type="image/png" sizes="192x192" href={`${process.env.hostBaseUrl}/android-icon-192x192.png`} />
        <link rel="apple-touch-icon" href={`${process.env.hostBaseUrl}/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${process.env.hostBaseUrl}/apple-touch-icon.png`} />
        <link rel="apple-touch-icon" sizes="57x57" href={`${process.env.hostBaseUrl}/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="60x60" href={`${process.env.hostBaseUrl}/apple-icon-60x60.png`} />
        <link rel="apple-touch-icon" sizes="72x72" href={`${process.env.hostBaseUrl}/apple-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${process.env.hostBaseUrl}/apple-icon-76x76.png`} />
        {/*<link rel="apple-touch-icon" sizes="114x114" href={`${process.env.hostBaseUrl}/apple-icon-114x114.png`} />*/}
        <link rel="apple-touch-icon" sizes="120x120" href={`${process.env.hostBaseUrl}/apple-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`${process.env.hostBaseUrl}/apple-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.hostBaseUrl}/apple-icon-180x180.png`} />
        <link rel="manifest" href="/manifest.json" />

        <meta name="msapplication-TileColor" content="#ffffff" />

        <meta name="theme-color" content={currentTheme === ConstHelper.LIGHT_THEME ? "#ffffff" : "#141416" } />

        <link rel="mask-icon" href={`${process.env.hostBaseUrl}/safari-pinned-tab.svg`} color="#5bbad5" />
        <meta name="msapplication-TileImage" content={`${process.env.hostBaseUrl}/ms-icon-144x144.png`} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta name="title" content={meta && meta.pageTitle ? meta && meta.pageTitle : 'UPYO-NFT'} />
        <meta name="description" content={meta && meta.pageMetaDescription ? meta.pageMetaDescription : ''} />

        {router && router.pathname && (router.pathname === "/404" || router.pathname === "/_error") ? null :
          <>
            <meta name="robots" content={meta && meta.metaRobots && meta.metaRobots !== '' ? meta.metaRobots : "index,follow"} />
            <link rel="canonical" href={canonical} />
            <meta property="og:type" content={`${router && router.pathname && router.pathname === "/article/[articleSlug]" ? 'article' : 'website'}`} />
            <meta property="og:title" content={meta && meta.pageTitle ? meta.pageTitle : 'UPYO-NFT'} />
            <meta property="og:description" content={meta && meta.pageMetaDescription ? meta.pageMetaDescription : ''} />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:site_name" content="UPYO" />
            {children.props.externalOgImage && children.props.externalOgImage !== '' ?
              <meta property="og:image" content={children.props.externalOgImage} /> :
              <meta property="og:image" content={ogImage} />}
            <meta property="og:image:alt" content={meta && meta.metaOgImgAlt && meta.metaOgImgAlt !== '' ? meta.metaOgImgAlt : 'NFT Marketplace in Saudi Arabia Social Sharing Image'} />
            <meta property="og:image:width" content='1200' />
            <meta property="og:image:height" content='630' />
            <meta property="og:image:type" content={'image/' + imageExt} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@UPYOcom" />
            <meta name="twitter:description" content={meta && meta.pageMetaDescription ? meta.pageMetaDescription : ''} />
            <meta name="twitter:title" content={meta && meta.pageTitle ? meta.pageTitle : ''} />
            {children.props.externalOgImage && children.props.externalOgImage !== '' ?
              <meta property="twitter:image" content={children.props.externalOgImage} /> :
              <meta name="twitter:image" content={ogImage} />}
            <meta name="twitter:image:alt" content={meta && meta.metaOgImgAlt && meta.metaOgImgAlt !== '' ? meta.metaOgImgAlt : 'NFT Marketplace in Saudi Arabia Social Sharing Image'} />
          </>
        }
        {children.props.isENSPage && children.props.isENSPage === true && isSafari &&
          <meta name="apple-itunes-app" content="app-id=1637224142" />}

        {totalNumberOfPages && totalNumberOfPages < 2 && totalNumberOfPages >= 1 ? "" :
          (page && page === 1 ?
            <link rel="next" href={process.env.hostBaseUrl  + (router.asPath.includes("?") ? router.asPath.split("?")[0].toString() : router.asPath) + '?page=2'} />
            : (page && page === totalNumberOfPages ?
              <link rel="prev" href={process.env.hostBaseUrl  + (router.asPath.includes("?") ? router.asPath.split("?")[0].toString() : router.asPath) + (page ? '?page=' + (parseInt(totalNumberOfPages) - 1).toString() : '')} />
              : (page && page < totalNumberOfPages ?
                <>
                  <link rel="prev" href={process.env.hostBaseUrl + (router.asPath.includes("?") ? router.asPath.split("?")[0].toString() : router.asPath) + (page && page === 2 ? '' : (page ? '?page=' + (parseInt(page) - 1).toString() : ''))} />
                  <link rel="next" href={process.env.hostBaseUrl + (router.asPath.includes("?") ? router.asPath.split("?")[0].toString() : router.asPath) + (page ? '?page=' + (parseInt(page) + 1).toString() : '')} />
                </>
                : ""
              ))
          )}
      </Head>

      {children.props.isENSPage && children.props.isENSPage === true && !isSafari &&
        <div className="d-block d-sm-none ensBanner">
          <AppBanner
            appInfo={appInfoEns}
            position="top"
            onClose={() => {
              console.log("onClose called");
            }}
            hidden={false}
          /></div>}

        <Header
            toggleTheme={toggleTheme}
          currLocale={children.props && children.props.currLocale}
          categoryList={children.props && children.props.catList}
          dynamicUrl={dynamicUrl}
          equivalentSlug={children.props.equivalentSlug && children.props.equivalentSlug} />

      <main id="main" className={isNewsPage ? layoutStyles.newsMain : ''}>{children}</main>
      <Footer
        locale={router.locale}
        currLocale={children.props && children.props.currLocale}
        categoryList={children.props && children.props.catList}
        pageId={children.props.pageId}
        pageName={children.props.pageName}
      />

      {/* Network change */}
      {!isNews ? <NetworkModal networkChangeModal={networkChangeModal} chainIndex={2} chainID={chain?.id} /> : <Fragment />}
      {!isNews && triggerSignature ? <SignWithDiffAccount signWithDiffACcounnt={signWithDiffACcounnt} disconnectForDiffAcc={disconnectForDiffAcc} isSignWithDiffAccount={triggerSignature} /> : <Fragment />}
    </>
  )
}