let domainUrl = `https://${process.env.VERCEL_URL}`;

if (process.env.VERCEL_GIT_COMMIT_REF == "blockchain") {
  domainUrl = "https://blockchain.upyo.com";
}

//domainUrl = 'http://localhost:3000'
   
 module.exports = {
  domainUrl: domainUrl,
  basePath: '',
  task24BaseUrl: 'https://www.24task.com',
  hostBaseUrl: domainUrl,

  // this url is used in switching to blog site  
  blogBaseUrl: domainUrl,
  defaultLanguage: 'en',
  defaultMeta: {
    title: "",
    description: "",
    keywords: ""
  },
  
  newsBlockchainurl: "https://ouwkp8t2yi.execute-api.me-south-1.amazonaws.com/dev/",

 // for development mode
 blockChainurl: "https://dev.upyo.io/",
 EnsApiurl: "https://tq2h1bfra2.execute-api.me-south-1.amazonaws.com/dev/",

  blockChainApiUrlImportCollection: "https://daemon.upyo.io",
  domain : "blockchain.upyo.com",
  moralisAuthUrl:"https://authapi.moralis.io/challenge/request/evm",
  chainId: 4,
 NEXT_PUBLIC_GTAG:'G-7MGBHSL6KT'
};

